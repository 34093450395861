import { Text } from "@clipboard-health/ui-react";
import { CardContent, Divider, List } from "@mui/material";
import { parseISO } from "date-fns";

import { type ShiftWithType } from "../types";
import { ShiftBottomSheetDateTimeInfoListItem } from "./DateTimeInfoListItem";
import { ShiftBottomSheetFacilityInfoListItem } from "./FacilityInfoListItem";
import { ShiftBottomSheetInnerCard } from "./InnerCard";
import { ShiftBottomSheetPayInfoListItem } from "./PayInfoListItem";

interface ShiftBottomSheetSummaryCardProps {
  shift: ShiftWithType;
}
export function ShiftBottomSheetSummaryCard(props: ShiftBottomSheetSummaryCardProps) {
  const { shift } = props;
  const { agentReq, name, start, end, offer, finalPay, originalAmount, facility } = shift;
  const { name: facilityName } = facility;

  const startDate = parseISO(start);
  const endDate = parseISO(end);

  return (
    <ShiftBottomSheetInnerCard>
      <CardContent
        sx={{
          padding: 6,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* // TODO: Use Pill component */}
        <Text variant="body2">{agentReq} - WIP</Text>

        <List>
          <ShiftBottomSheetDateTimeInfoListItem
            name={name}
            startDate={startDate}
            endDate={endDate}
          />

          <Divider />

          <ShiftBottomSheetPayInfoListItem
            offer={offer}
            finalPay={finalPay}
            originalAmount={originalAmount}
          />

          <Divider />

          <ShiftBottomSheetFacilityInfoListItem facilityName={facilityName} />
        </List>
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}

import { isDefined } from "@clipboard-health/util-ts";
import { URGENT_SHIFTS_PATH } from "@src/appV2/OpenShifts/paths";
import { useUrgentShifts } from "@src/appV2/Shifts/UrgentShifts/api/useUrgentShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { Callout } from "../../components/Callout";

export function UrgentShiftsCallout() {
  const worker = useDefinedWorker();

  const history = useHistory();

  // TODO: This will likely change later on as we'll be fetching urgent shifts in a context level and filter them by selected dates
  // To simplify things for now, we'll fetch them here and refactor later on
  const { data: urgentShiftsData, isSuccess: isUrgentShiftsSuccessful } = useUrgentShifts(
    {
      coordinates: worker.geoLocation?.coordinates,
      getCountOnly: true,
    },
    {
      enabled: isDefined(worker.geoLocation?.coordinates),
    }
  );

  if (!isUrgentShiftsSuccessful || urgentShiftsData.total <= 0) {
    return null;
  }

  return (
    <Callout
      title={`${urgentShiftsData.total} Urgent ${pluralize("shift")}`}
      iconType="time-flies"
      sx={(theme) => ({
        backgroundColor: theme.palette.shifts?.urgent.label,
        color: theme.palette.shifts?.urgent.text,
      })}
      onClick={() => {
        history.push(URGENT_SHIFTS_PATH);
      }}
    />
  );
}

import { Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib/Calendar";
import { type ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

import { DateWithDuration } from "../../DateTime/DateWithDuration";
import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { ShiftPeriodIcon } from "../ShiftPeriodIcon";

interface ShiftCardDateTimeInfoProps {
  dateRange: DateRange;
  shiftPeriod: ShiftPeriod;
}

export function ShiftCardDateTimeInfo(props: ShiftCardDateTimeInfoProps) {
  const { dateRange, shiftPeriod } = props;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ShiftPeriodIcon shiftPeriod={shiftPeriod} size="small" />
        <DateWithDuration
          dateRange={dateRange}
          variant="body2"
          color={(theme) => theme.palette.text.secondary}
        />
      </Stack>

      <TimeRangeLabel semibold dateRange={dateRange} variant="h5" />
    </Stack>
  );
}

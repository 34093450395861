import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

import { CbhIcon, type CbhIconProps, type IconType } from "../components/CbhIcon";

interface ShiftPeriodIconProps extends Pick<CbhIconProps, "size"> {
  shiftPeriod: ShiftPeriod;
}

const iconTypeMap: Record<ShiftPeriod, IconType> = {
  [ShiftPeriod.AM]: "sun-filled",
  [ShiftPeriod.PM]: "sunset-filled",
  [ShiftPeriod.NOC]: "moon-filled",
};

export function ShiftPeriodIcon(props: ShiftPeriodIconProps) {
  const { shiftPeriod, ...restProps } = props;

  return <CbhIcon type={iconTypeMap[shiftPeriod]} {...restProps} />;
}

import { Text } from "@clipboard-health/ui-react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { CbhIcon } from "../../components/CbhIcon";
import { getDistanceToFacility } from "../getDistanceToFacility";

interface ShiftBottomSheetFacilityInfoListItemProps {
  facilityName: string;
}

export function ShiftBottomSheetFacilityInfoListItem(
  props: ShiftBottomSheetFacilityInfoListItemProps
) {
  const { facilityName } = props;

  return (
    <ListItemButton>
      <ListItemIcon>
        <CbhIcon type="map-pin" size="medium" />
      </ListItemIcon>

      <ListItemText
        disableTypography
        primary={
          <Text semibold variant="h5">
            {facilityName}
          </Text>
        }
        secondary={<Text variant="body2">{getDistanceToFacility()}</Text>}
      />
    </ListItemButton>
  );
}

import { CardActionArea, CardContent, Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib/Calendar";
import { parseISO } from "date-fns";

import { type ShiftWithType } from "../types";
import { ShiftCardDateTimeInfo } from "./DateTimeInfo";
import { ShiftCardFacilityInfo } from "./FacilityInfo";
import { ShiftCardPayInfoCard } from "./PayInfoCard";
import { ShiftCardPills } from "./Pills";
import { ShiftCardWrapper } from "./Wrapper";

interface ShiftCardProps {
  shift: ShiftWithType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function ShiftCard(props: ShiftCardProps) {
  const { shift, onClick } = props;
  const { name, start, end, offer, finalPay, originalAmount, facility } = shift;

  const dateRange: DateRange = { startDate: parseISO(start), endDate: parseISO(end) };

  return (
    <ShiftCardWrapper shiftType={shift.type}>
      <CardActionArea sx={{ height: "100%" }} onClick={onClick}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 5,
            height: "100%",
          }}
        >
          <ShiftCardPills shift={shift} />

          <ShiftCardDateTimeInfo dateRange={dateRange} shiftPeriod={name} />

          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
            <ShiftCardFacilityInfo facility={facility} />

            <ShiftCardPayInfoCard
              offer={offer}
              finalPay={finalPay}
              originalAmount={originalAmount}
            />
          </Stack>
        </CardContent>
      </CardActionArea>
    </ShiftCardWrapper>
  );
}

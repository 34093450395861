import { Title } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";

import { DialogFooter } from "../../components/DialogFooter";
import { HorizontalScrollView } from "../../components/HorizontalScrollView";
import { ShiftBottomSheetCancellationPoliciesCard } from "./CancellationPoliciesCard";
import { ShiftBottomSheetFacilityHighlightsCard } from "./FacilityHighlightsCard";
import { ShiftBottomSheetFacilityMapLocationCard } from "./FacilityMapLocationCard";
import { ShiftBottomSheetFacilityPhotosCard } from "./FacilityPhotosCard";
import { ShiftBottomSheetFacilityReviewsCard } from "./FacilityReviewsCard";
import { ShiftBottomSheetSummaryCard } from "./SummaryCard";
import { ShiftBottomSheetWrapper, type ShiftBottomSheetWrapperProps } from "./Wrapper";

interface ShiftBottomSheetProps
  extends Pick<ShiftBottomSheetWrapperProps, "shift" | "modalState"> {}

export function ShiftBottomSheet(props: ShiftBottomSheetProps) {
  const { shift, modalState } = props;
  const { facility } = shift;
  const { _id: facilityId } = facility;

  return (
    <ShiftBottomSheetWrapper
      shift={shift}
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <Button fullWidth size="large" variant="contained">
            Book this shift
          </Button>
        </DialogFooter>
      }
    >
      <Title variant="h3" component="h3" sx={{ padding: 7 }}>
        Confirm booking
      </Title>

      <HorizontalScrollView stretch sx={{ px: 5 }}>
        <ShiftBottomSheetSummaryCard shift={shift} />
        <ShiftBottomSheetCancellationPoliciesCard />
        <ShiftBottomSheetFacilityPhotosCard facilityId={String(facilityId)} />
        <ShiftBottomSheetFacilityHighlightsCard />
        <ShiftBottomSheetFacilityReviewsCard />
        <ShiftBottomSheetFacilityMapLocationCard />
      </HorizontalScrollView>
    </ShiftBottomSheetWrapper>
  );
}

import { RootPaths } from "@src/appV2/App/paths";
import { useAgentPendingShiftInvites } from "@src/appV2/Shifts/ShiftInvites/api/useAgentPendingShiftInvites";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { Callout } from "../../components/Callout";

export function ShiftInvitesCallout() {
  const { data: shiftInvites, isSuccess } = useAgentPendingShiftInvites();

  const history = useHistory();

  const pendingShiftInvites = isSuccess ? shiftInvites.data : [];
  const shiftInviteCount = pendingShiftInvites.length;

  if (shiftInviteCount <= 0) {
    return null;
  }

  return (
    <Callout
      title={`${shiftInviteCount} Shift ${pluralize("invite", shiftInviteCount)}`}
      iconType="invite"
      sx={(theme) => ({
        backgroundColor: theme.palette.shifts?.priority.background,
        color: theme.palette.shifts?.priority.text,
      })}
      onClick={() => {
        history.push(`${RootPaths.APP_V2_HOME}/myShifts/shiftInvites`);
      }}
    />
  );
}

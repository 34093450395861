import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { CbhIcon } from "../../components/CbhIcon";
import { getFormattedShiftPayBreakdown } from "../getFormattedShiftPayBreakdown";
import { type ShiftWithType } from "../types";

interface ShiftBottomSheetPayInfoListItemProps {
  offer: ShiftWithType["offer"];
  finalPay: ShiftWithType["finalPay"];
  originalAmount: ShiftWithType["originalAmount"];
}

export function ShiftBottomSheetPayInfoListItem(props: ShiftBottomSheetPayInfoListItemProps) {
  const { offer, finalPay, originalAmount } = props;
  const payBreakdown = getFormattedShiftPayBreakdown(offer, finalPay, originalAmount);

  return (
    <ListItem>
      <ListItemIcon>
        <CbhIcon type="bolt-filled" size="medium" />
      </ListItemIcon>

      {/* TODO: Add logic for InstantPay pill being shown depending on shift data / user data */}
      <ListItemText primary={payBreakdown?.totalPay} secondary={payBreakdown?.hourlyPay} />
    </ListItem>
  );
}

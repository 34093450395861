import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { FacilityAvatar } from "../../components/FacilityAvatar";
import { getDistanceToFacility } from "../getDistanceToFacility";
import { type ShiftWithType } from "../types";

interface ShiftCardFacilityInfoProps {
  facility: ShiftWithType["facility"];
}

export function ShiftCardFacilityInfo(props: ShiftCardFacilityInfoProps) {
  const { facility } = props;
  const { _id: facilityId, name: facilityName } = facility;

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <FacilityAvatar facilityId={String(facilityId)} size="small" />

      <Stack spacing={1} sx={{ overflow: "hidden" }}>
        <Text
          semibold
          variant="body2"
          sx={{
            // clamp text to max 2 lines
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {facilityName}
        </Text>

        {/* TODO: Add logic for resolving the distance */}
        <Text variant="caption" color={(theme) => theme.palette.text.secondary}>
          {getDistanceToFacility()}
        </Text>
      </Stack>
    </Stack>
  );
}

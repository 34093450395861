import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { type ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

import { DateWithDuration } from "../../DateTime/DateWithDuration";
import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { ShiftPeriodIcon } from "../ShiftPeriodIcon";

interface ShiftBottomSheetDateTimeInfoListItemProps {
  name: ShiftPeriod;
  startDate: Date;
  endDate: Date;
}

export function ShiftBottomSheetDateTimeInfoListItem(
  props: ShiftBottomSheetDateTimeInfoListItemProps
) {
  const { name, startDate, endDate } = props;

  return (
    <ListItem>
      <ListItemIcon>
        <ShiftPeriodIcon shiftPeriod={name} size="medium" />
      </ListItemIcon>

      <ListItemText
        primary={<TimeRangeLabel variant="h5" dateRange={{ startDate, endDate }} />}
        secondary={<DateWithDuration dateRange={{ startDate, endDate }} variant="body2" />}
      />
    </ListItem>
  );
}

import { mergeSxProps } from "@clipboard-health/ui-react";
import { type Theme } from "@mui/material";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

import { BottomSheet, type BottomSheetProps } from "../../components/BottomSheet";
import { ShiftType } from "../constants";
import { type ShiftWithType } from "../types";

export interface ShiftBottomSheetWrapperProps extends BottomSheetProps {
  shift: ShiftWithType;
}

function resolveBackground(theme: Theme, shift: ShiftWithType) {
  const { type, name } = shift;

  switch (type) {
    case ShiftType.GENERAL: {
      if (name === ShiftPeriod.AM) {
        return theme.palette.shifts?.day.gradient;
      }

      if (name === ShiftPeriod.PM) {
        return theme.palette.shifts?.evening.gradient;
      }

      if (name === ShiftPeriod.NOC) {
        return theme.palette.shifts?.overnight.gradient;
      }

      return undefined;
    }

    case ShiftType.URGENT: {
      return theme.palette.shifts?.urgent.gradient;
    }

    case ShiftType.PRIORITY: {
      return theme.palette.shifts?.priority.gradient;
    }

    default: {
      return undefined;
    }
  }
}

export function ShiftBottomSheetWrapper(props: ShiftBottomSheetWrapperProps) {
  const { shift, paperSx, ...restProps } = props;

  return (
    <BottomSheet
      {...restProps}
      paperSx={mergeSxProps((theme) => ({ background: resolveBackground(theme, shift) }), paperSx)}
    />
  );
}

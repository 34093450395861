import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { type ShiftWithType } from "../types";

interface ShiftCardPillsProps {
  shift: ShiftWithType;
}

export function ShiftCardPills(props: ShiftCardPillsProps) {
  const { shift } = props;
  const { agentReq } = shift;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
      <Text>WIP - Pills</Text>
      <Text>{agentReq}</Text>
    </Stack>
  );
}

import { mergeSxProps } from "@clipboard-health/ui-react";

import { Card, type CardProps } from "../../components/Card";

interface ShiftBottomSheetInnerCardProps extends CardProps {}

export function ShiftBottomSheetInnerCard(props: ShiftBottomSheetInnerCardProps) {
  const { sx, ...restProps } = props;

  return <Card {...restProps} sx={mergeSxProps({ height: "23.75rem" }, sx)} />;
}
